import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

import image1 from '../images/2016.jpeg'; // Import your images here
import image6 from '../images/2022.jpg';
import image7 from '../images/2023.jpg';

function GridExample() {
  return (
    <center>
      <Container className="mt-5">
        <Row xs={1} md={3} className="g-4 justify-content-center">
          {[
            { image: image1, title: 'hackX 1.0 - 2016', link: 'https://web.facebook.com/media/set/?set=a.575537137938242&type=3' },
            { image: image7, title: 'hackX 8.0', link: 'https://web.facebook.com/media/set/?set=a.575537137938242&type=3' },
            { image: image7, title: 'hackX 8.0', link: 'https://web.facebook.com/media/set/?set=a.575537137938242&type=3' },
            { image: image7, title: 'hackX 8.0', link: 'https://web.facebook.com/media/set/?set=a.575537137938242&type=3' },
            { image: image7, title: 'hackX 8.0', link: 'https://web.facebook.com/media/set/?set=a.575537137938242&type=3' },
            { image: image6, title: 'hackX 7.0 - 2022', link: 'https://web.facebook.com/media/set/?set=a.3660886667481109&type=3' },
            { image: image7, title: 'hackX 8.0 - 2023', link: 'https://web.facebook.com/nyouseeme/posts/pfbid029AZnhsuzt7aph8kkfro8YU1LQLdQDtjh8tFAsvtkVQosZU7d85LwLtUY9nzF47Z2l' }
          ].map((card, idx) => (
            <Col key={idx} className="mb-3">
              <a href={card.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                <Card style={{ width: '18rem', position: 'relative', overflow: 'hidden',borderRadius:"25px" }}>
                  <div style={{ height: '200px', width: '100%', overflow: 'hidden' }}>
                    <Card.Img
                      variant="top"
                      src={card.image}
                      style={{
                        height: '100%',
                        width: '100%',
                        objectFit: 'cover'
                        
                      }}
                    />
                  </div>
                  <div style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    color: 'white',
                    textAlign: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    padding: '10px',
                    transition: 'all 0.3s ease',
                    fontFamily: 'Orbitron, sans-serif',
                    fontOpticalSizing: 'auto',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    
                  }}
                    className="card-title-overlay">
                    <h5>{card.title}</h5>
                  </div>
                  <style>
                    {`
                      .card:hover .card-title-overlay {
                        top: 50%;
                        transform: translateY(-50%);
                        bottom: auto;
                      }
                    `}
                  </style>
                </Card>
              </a>
            </Col>
          ))}
        </Row>
      </Container>
    </center>
  );
}

export default GridExample;
